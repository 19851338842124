/* Scrollbar */
::-webkit-scrollbar {
    width: 2px!important;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2)!important;
    border-radius: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px -moz-mac-chrome-inactive!important;
    border-radius: 10px;
}
