@font-face {
    font-family: 'GTBold';
    src: url('./GT Eesti Pro Display Bold.ttf');
}

@font-face {
    font-family: 'GT';
    src: url('./GT Eesti Pro Display Regular.ttf');
}

@font-face {
    font-family: 'GTTextBold';
    src: url('./GT Eesti Pro Text Bold.ttf');
}

@font-face {
    font-family: 'GTTextLight';
    src: url('./GT Eesti Pro Text Light.ttf');
}

@font-face {
    font-family: 'GTTextMedium';
    src: url('./GT Eesti Pro Text Medium.ttf');
}

@font-face {
    font-family: 'GTText';
    src: url('./GT Eesti Pro Text Regular.ttf');
}

